<template>
  <div class="inbound">
      <div class="info">
        <h1>入库单列表</h1>
        <div class="filter-term">
          <a-row>
            <a-col :span="7">
              <label>入库单号</label>
              <a-input placeholder="请输入" />
            </a-col>
            <a-col :span="7">
              <label>入库类型</label>
              <a-select>
                <a-select-option value="类型1">类型1</a-select-option>
                <a-select-option value="类型2">类型2</a-select-option>
                <a-select-option value="类型3">类型3</a-select-option>
              </a-select>
            </a-col>
            <a-col :span="7">
              <label>操作人</label>
              <a-input placeholder="请输入" />
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="7">
              <label>入库仓库</label>
              <a-input placeholder="请输入" />
            </a-col>
            <a-col :span="7">
              <label>状态</label>
              <a-select>
                <a-select-option value="类型1">类型1</a-select-option>
                <a-select-option value="类型2">类型2</a-select-option>
                <a-select-option value="类型3">类型3</a-select-option>
              </a-select>
            </a-col>
            <a-col :span="7">
              <a-button type="primary">查询</a-button>
            </a-col>
          </a-row>
          <a-button type="primary" class="add-btn" @click="goAdd">新增入库单</a-button>
        </div>
        <a-table :columns="columns" :data-source="data" :pagination="pagination">
          <span slot="action" class="href-btn">
            <a>执行入库</a>
            <a>查看详情</a>
          </span>
        </a-table>
      </div>
  </div>
</template>

<script>
const columns = [
  {
    title: '入库单号',
    dataIndex: 'inboundNum',
  },
  {
    title: '来源单号',
    dataIndex: 'sourceNum',
  },
  {
    title: '入库类型',
    dataIndex: 'inboundType',
  },
  {
    title: '入库数量',
    dataIndex: 'inboundDigit',
  },
  {
    title: '操作人',
    dataIndex: 'operater',
  },
  {
    title: '操作时间',
    dataIndex: 'operateTime',
  },
  {
    title: '状态',
    dataIndex: 'state',
  },
  {
    title: '入库仓库',
    dataIndex: 'inboundDepot',
  },
  {
    title: '备注',
    dataIndex: 'remark',
  },
  {
    title: '操作',
    scopedSlots: { customRender: 'action' },
  },
];

const data = [
  {
    key: '1',
    inboundNum: 'RK20210721386083',
    sourceNum: 'RK20210721386083',
    inboundType: '生产入库单',
    inboundDigit: '1000',
    operater: '吴嘉铭',
    operateTime: '2021-07-21 16:42',
    state: '预入库',
    inboundDepot: '福永1号仓库-结构零部件仓-4#货架',
    remark: '', 
  },
];
export default {
  data() {
    return {
      data,
      columns,
      pagination: {
        current: 1,
        total: 0,
        pageSize: 10, //每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"], //每页中显示的数据
        showTotal: (total) => `共有 ${total} 条数据`, //分页中显示总的数据
      },
    };
  },
  methods: {

    // 跳转新增入库单
    goAdd() {
      this.$router.push({ path: `/Storage/AddInbound` });
    },
  },
};
</script>

<style lang="scss" scoped>
  .inbound{
    padding: 30px;

    .info{
      background-color: #ffffff;
      padding: 30px;

      h1{
        font-size: 18px;
        font-weight: bold;
        border-bottom: 1px solid #dddddd;
        padding-bottom: 10px;
      }

      .filter-term{
        margin: 20px 0;
        .ant-row{
          margin-bottom: 15px;

          label{
            margin-right: 10px;
            width: 60px;
            display: inline-block;
            font-size: 14px;
          }

          .ant-input{
            width: 50%;
          }

          .ant-select{
            width: 50%;
          }
        } 
        .add-btn{
          margin-top: 20px;
        }
      }

      .href-btn a{
        margin-right: 10px;
      }
    }
  }
</style>
